<template>
  <div class="page">
    <div class="page_body">
      <div class="input_zone mt100">
        <van-cell-group>
          <van-field
            v-validate="'required|numeric|length:11|max:11|mobile'"
            v-model="celPhone"
            name="celPhone"
            type="text"
            :error-message="errors.first('celPhone')"
            placeholder="请输入手机号"
          />
        </van-cell-group>

        <van-cell-group class="mt10">
          <van-field
            v-validate="'required|numeric|length:4|max:4'"
            v-model="captcha"
            name="captcha"
            type="text"
            :error-message="errors.first('captcha')"
            placeholder="请输入验证码"
          >
            <CaptchaBtn
              slot="button"
              :phone="celPhone"
              scene="register"
              @click="sendcode"
              ref="CaptchaBtn"
            />
          </van-field>
        </van-cell-group>

        <van-cell-group class="mt10">
          <van-field
            v-validate="'required|min:6|max:16'"
            v-model="password"
            name="password"
            type="password"
            :error-message="errors.first('password')"
            placeholder="请输入密码"
          />
        </van-cell-group>

        <div class="mt15 tl p015">
          <van-checkbox
            v-validate="'required:true'"
            v-model="checked"
            type="Checkbox"
            name="license"
            :label-disabled="true"
          >
            <router-link
              to="/html?url=http://jfs.quanminhuyu.com/Article-detail?id=18112410141098182"
              class="f14"
              style="color:#777;"
            >已阅读并同意《淘街坊用户协议》</router-link>
          </van-checkbox>
          <div class="van-field__error-message p015 mt5 ml15">{{errors.first('license')}}</div>
        </div>
      </div>

      <div class="mt50 ml20 mr20">
        <PinkBtn text="马上注册" @click="validate"/>
      </div>

      <p class="info_zone mt30 tc">
        <router-link to="/login">已有淘街坊帐号？点我登录</router-link>
      </p>
    </div>

    <div class="copyright tc">&copy; 淘街坊 - 发现身边优惠</div>
  </div>
</template>

<script>
import Vue from "vue";
import StatusBar from "@/components/StatusBar";
import NavBar from "@/components/NavBar";
import PinkBtn from "@/components/PinkBtn";
import CaptchaBtn from "@/components/CaptchaBtn";
import { Field, Checkbox } from "vant";
import { register } from "@/api/auth";
import ls from "@/libs/localstore";

Vue.use(Field).use(Checkbox);

require("@/libs/validate.js");

export default {
  name: "Reg",
  components: {
    StatusBar,
    NavBar,
    PinkBtn,
    CaptchaBtn
  },
  data() {
    return {
      img: {
        close_btn: require("@/assets/common/close_btn.png"),
        wechat_login_btn: require("@/assets/login/wechat_login_btn.png")
      },
      celPhone: null,
      captcha: null,
      password: "",
      checked: true
    };
  },
  methods: {
    validate: function() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.reg();
        } else {
          this.$toast("请按提示正确填写所有信息");
        }
      });
    },
    sendcode: function() {
      this.$validator.validate("celPhone", this.celPhone).then(result => {
        if (result) {
          this.$refs.CaptchaBtn.send();
        }
      });
    },
    reg: function() {
      let params = {
        phone: this.celPhone,
        code: this.captcha,
        password: this.password
      };

      register(params)
        .then(res => {
          if (res.errCode == 0) {
            this.$toast.success("注册成功");
            // 注册成功后的业务
            this.regSuccessCallback(res.data.token, res.data.userInfo);
          } else {
            this.$toast(res.errMsg);
          }
        })
        .catch(err => {
          this.$toast(err.errMsg);
          // 做些错误处理
        });
    },
    regSuccessCallback: function(token, userInfo) {
      // 更新token
      ls.setToken(token);
      // 更新个人信息
      ls.setUserInfo(userInfo);
      this.$router.push("/shop");
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.page {
  background: #fff;
}
.input_zone {
  padding: 0 30px;
}
.findpwd {
  color: #4a4a4a;
}
.info_zone {
  font-size: 16px;
  color: #4a4a4a;
}
.info_zone a {
  color: #4a4a4a;
}
.van-cell {
  font-size: 16px;
}
[class*="van-hairline"]::after {
  border-top-color: transparent !important;
}
</style>
