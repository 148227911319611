<template>
  <van-button
    id="btn"
    :disabled="realDisable"
    :style="styles"
    size="small"
    plain
    @click="handler"
  >{{realText}}</van-button>
</template>

<script>
import Vue from "vue";
import { Button } from "vant";
import { sendCaptcha } from "@/api/auth";

Vue.use(Button);

export default {
  name: "CaptchaBtn",
  components: {
    Button
  },
  props: {
    color: {
      type: String,
      default: "#F40337"
    },
    text: {
      type: String,
      default: "发送验证码"
    },
    disable: {
      type: Boolean,
      default: false
    },
    phone: {
      type: String,
      default: '0'
    },
    scene: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      realText: this.text,
      realDisable: this.disable
    };
  },
  computed: {
    styles: function() {
      return this.realDisable
        ? ""
        : "border:solid 1px" + this.color + ";color:" + this.color + ";";
    }
  },
  methods: {
    handler: function() {
      this.$emit("click");
    },
    send: function() {
      let params = {
        phone: this.phone,
        type: this.scene
      };

      sendCaptcha(params)
        .then(res => {
          if (res.errCode == 0) {
            this.changeBtnStatus();
          } else {
            this.$toast.fail(res.errMsg);
          }
        })
        .catch(err => {
          this.$toast(err.errMsg);
          // 做些错误处理
        });
    },
    changeBtnStatus: function() {
      this.$toast.success("验证码已发送");
      this.realDisable = true;
      this.realText = "60秒后重发";

      let that = this;
      let time = 60;
      let timer = setInterval(function() {
        time--;
        if (time == 0) {
          // 恢复可发送状态
          clearInterval(timer);
          that.realDisable = false;
          that.realText = "重新发送";
        } else {
          that.realText = time + "秒后重发";
        }
      }, 1000);
    }
  }
};
</script>

<style scoped>
#btn {
  border-radius: 30px !important;
}
</style>
